import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import Homepage from './Homepage/Homepage';
import HomeThree from './HomeThree';
import HomeTwo from './HomeTwo';

export default function Routes() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path={'/'}>
            <HomeTwo />
          </Route>
          <Route exact path={'/home'}>
            <HomeTwo />
          </Route>
          <Route exact path={'/resort'}>
            <Homepage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}
