/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { Link } from 'react-router-dom';
import Hotel from '../Assets/Logos/hotel.png';
import Building from '../Assets/Logos/build.png';
import Logo from '../Assets/symbol.png';
import TransvisionTextLogo from '../Assets/Logos/TRANSVISIONTEXTLOGO.png';

const YouTubeVideo = ({ youtubeId }) => {
  return (
    <div
      className="video"
      style={{
        position: 'relative',
        paddingBottom: '56.25%' /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        autoPlay
        frameBorder="0"
      />
    </div>
  );
};

const NavBar = () => (
  <div className="lg:flex justify-between p-4 w-full uppercase bg-black text-white tracking-widest text-sm">
    <div className="flex-1 p-3 text-5xl text-left sm:text-center font-bebas">
      <Link
        className="flex items-center"
        data-aos="fade-up"
        data-aos-delay="100"
        to="/"
      >
        <img width="150px" src={Logo} alt="Transvision Logo" />
        <img
          className="w-1/3"
          src={TransvisionTextLogo}
          alt="Transvision Logo"
        />
      </Link>
    </div>
    <Link
      data-aos="fade-up"
      data-aos-delay="100"
      className="p-3 hidden lg:inline"
      to="/"
    >
      History
    </Link>
    <Link
      data-aos="fade-up"
      data-aos-delay="200"
      className="p-3 hidden lg:inline"
      to="/"
    >
      Verticals
    </Link>
    <Link
      data-aos="fade-up"
      data-aos-delay="300"
      className="p-3 hidden lg:inline"
      to="/"
    >
      Associations
    </Link>
    <Link
      data-aos="fade-up"
      data-aos-delay="400"
      className="p-3 hidden lg:inline"
      to="/"
    >
      Brands
    </Link>
    <Link
      data-aos="fade-up"
      data-aos-delay="500"
      className="p-3 hidden lg:inline"
      to="/"
    >
      Consultancy
    </Link>
  </div>
);

const HeroSection = () => (
  <div className="bg-black">
    <div className="lg:p-64 lg:flex md:block sm:block h-full justify-center items-center">
      <div className="lg:w-1/2 md:w-auto sm:w-auto lg:text-right pt-10 pr-10 pl-10 lg:pr-40">
        <p
          data-aos="fade-up"
          data-aos-delay="600"
          className="lg:text-6xl uppercase text-white text-6xl font-bebas leading-none"
        >
          {' '}
          TRANSVISION
        </p>
      </div>
      <div className="lg:w-1/2 pt-10 pr-10 pl-10 lg:p-0">
        <p
          data-aos="fade-up"
          data-aos-delay="700"
          className="text-md lg:text-left lg:w-1/2 text-white"
        >
          A company born with an idea to execute great ideas
          <br />– Goldie Tucker
        </p>
      </div>
    </div>
    <div className="flex bg-black text-white p-10">
      <div className="w-1/3 text-center">
        <div
          data-aos="fade-up"
          data-aos-delay="800"
          className="flex items-center"
        >
          <img className="p-10 hidden lg:inline " alt="hotel" src={Hotel} />
          <p className="uppercase tracking-wider"> Hospitality </p>
        </div>
      </div>
      <div className="w-1/3 text-center">
        <div
          data-aos="fade-up"
          data-aos-delay="800"
          className="flex items-center"
        >
          <img className="p-10 hidden lg:inline" alt="hotel" src={Building} />
          <p className="uppercase tracking-wider"> Real Estate </p>
        </div>{' '}
      </div>
      <div className="w-1/3 text-center">
        <div
          data-aos="fade-up"
          data-aos-delay="800"
          className="flex items-center"
        >
          <img className="p-10 hidden lg:inline" alt="hotel" src={Hotel} />
          <p className="uppercase tracking-wider"> Expert Consultations </p>
        </div>{' '}
      </div>
    </div>
  </div>
);

const HistorySection = () => (
  <div className="h-screen">
    <div className="flex h-full justify-center items-center">
      <div className="w-full lg:w-1/2 p-12 lg:p-64">
        <h1
          data-aos="fade-left"
          data-aos-delay="100"
          className="uppercase text-6xl font-bebas"
        >
          GLORIOUS DECADE
        </h1>
        <p data-aos="fade-left" data-aos-delay="100">
          A company striving for excellence & advancement in varied industries
          ranging from hospitality, service, entertainment and a few more.
          <br />
        </p>
        <br />
        <br />
        <h1
          data-aos="fade-left"
          data-aos-delay="100"
          className="uppercase text-6xl font-bebas"
        >
          Our Mantra
        </h1>
        <p data-aos="fade-left" data-aos-delay="100">
          We just love to create; everything is possible with immense passion.
          <br />
        </p>
        <br />
        <br />
        <h1
          data-aos="fade-left"
          data-aos-delay="100"
          className="uppercase text-3xl font-bebas"
        >
          YEARS OF TRANSVISION
        </h1>
        <p data-aos="fade-left" data-aos-delay="100">
          With a strong leadership panel, Transvision has made all efforts to
          translate how entertainment is perceived having a strong backbone by
          creatively producing a huge bank of content for various broadcasters
          in India, took strides of understanding in every business venture with
          appropriate implementation power. A vast experience in hospitality
          industry with expert management by handling a 4 star Hotel in Dubai &
          5 star offices in Mumbai, Transvision has curated skills to empower
          other brands and businesses too with highest efficiency adhering to
          updated technologies by advancement of internal systems from time to
          time.
          <br />
        </p>
      </div>
      <div data-aos="fade-right" data-aos-delay="100" className="w-0 lg:w-1/2">
        <img
          style={{ boxShadow: ' 0px 7px 51px 3px rgba(158,158,158,0.9)' }}
          alt="Dubai"
          className="w-1/2 shadow-2xl text-center"
          src="https://images.unsplash.com/photo-1546412414-c2658fffe7d9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
        ></img>
      </div>
    </div>
  </div>
);

const ManagementSection = () => (
  <div
    data-aos="fade-up"
    data-aos-delay="100"
    style={{
      background:
        'url(https://images.unsplash.com/photo-1489516408517-0c0a15662682?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80)',
      backgroundSize: 'cover',
    }}
    className="bg-cover p-0 lg:p-64 relative lg:h-screen"
  >
    <div className="bg-black min-w-full opacity-25 absolute z-0"></div>

    <div className="block lg:flex h-full p-12">
      <div className="w-full lg:w-1/2 lg:flex lg:items-end h-full lg:pb-64 justify-end">
        <h1
          data-aos="fade-up"
          data-aos-delay="300"
          className="text-white text-6xl font-bebas uppercase z-10 text-left lg:text-right lg:pr-20"
        >
          Management <br /> Expertise
        </h1>
      </div>
      <div className="w-full lg:w-1/2 flex pb-64 justify-start items-center">
        <p
          data-aos="fade-up"
          data-aos-delay="500"
          className="w-full lg:w-1/2 text-white font-medium"
        >
          With a strong leadership panel, Transvision is set to translate how
          hospitality & entertainment is percieved currently. We take pride in
          our creative force.
        </p>
      </div>
    </div>
  </div>
);

const RealEstate = () => (
  <div className="h-full">
    <div className="w-full text-center p-4 lgp-32">
      <h1
        data-aos="fade-up"
        data-aos-delay="100"
        className="uppercase text-4xl font-bebas  pt-10"
      >
        {' '}
        In Depth Experience <br /> Of Real Estate <br /> Management{' '}
      </h1>
    </div>
    <div style={{ height: '120vh' }} className="flex">
      <div
        data-aos="fade-right"
        data-aos-delay="200"
        style={{
          background:
            'url(https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80)',
          backgroundSize: 'cover',
        }}
        className="w-1/2 h-full"
      ></div>
      <div data-aos="fade-left" data-aos-delay="200" className="lg:w-1/2 p-32">
        <h1 className="text-6xl font-bebas"> 5 Star Offices </h1>
        <p className="pt-4 lg:pr-64 font-medium items-center">
          Great companies are built on top of great motivation.
          <br />
          <br />
          Having a luxurious office with all the support one needs for aids to
          trade in the beginning is all that is required for nurturing a great
          business idea, we act as one consolidated service.
          <br />
          <br />
          Imagine a place where you could work in a 5 star office, with all the
          amenities by your side with posh meeting rooms, vibrant conference
          rooms, taking breaks in a trendy cafeteria with all the secretarial
          management & legal services taken care of.
        </p>
        <br />
        <br />
      </div>
    </div>
  </div>
);

const IntegratedResort = () => (
  <>
    <div className="p-12 lg:p-32 lg:w-1/2">
      <h1 className="uppercase text-6xl font-bebas">
        {' '}
        <p data-aos="fade-up" data-aos-delay="100">
          {' '}
          Upcoming Project,{' '}
        </p>{' '}
        <p data-aos="fade-up" data-aos-delay="200">
          {' '}
          An Integrated Resort{' '}
        </p>
      </h1>
      <br />
      <p data-aos="fade-up" data-aos-delay="300" className="font-medium">
        At Transivison, we are creating a grand platform for gaming,
        entertainment & shopping experience. We are currently working on an
        Integrated Resort that is close to the vibrant city of Aamchi Mumbai
        which is getting set to change the landscape of tourism in India.
      </p>
      <br />
      <button
        data-aos="fade-up"
        data-aos-delay="400"
        className="w-full p-4 uppercase bg-black text-left text-white shadow-xl rounded-sm"
      >
        Learn More
      </button>
    </div>
    <div className="p-12 lg:p-32 bg-black text-center">
      <h1 className="text-3xl text-white">
        <p
          className="uppercase font-bebas"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          Contact Us
        </p>
        <p
          className="text-sm pl-12 pr-12 pt-2"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          W’ed Love To Listen <br /> To Your Dreams <br />
          And Help You Achieve Them <br /> We Are The Bridge Between You And
          Your Dreams.
        </p>
        <p
          className="text-sm pl-12 pr-12 pt-2"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          So reach out to us at goldie@transvisionindia.com
        </p>
      </h1>
      <br />
      <br />
    </div>
  </>
);
export default function HomeTwo() {
  return (
    <div className="">
      <NavBar />
      <HeroSection />
      <HistorySection />
      <ManagementSection />
      <RealEstate />
      <IntegratedResort />
      <YouTubeVideo youtubeId={'/6L92cAVyov8'} />
    </div>
  );
}
