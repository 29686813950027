/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Logo from '../Assets/symbol.png';
import TransvisionTextLogo from '../Assets/Logos/TRANSVISIONTEXTLOGO.png';
import { animated, useSpring, config, useTransition } from 'react-spring';

export default function HomeThree() {
  const Themes = [
    {
      imageURL:
        'https://images.unsplash.com/photo-1538429449137-e0e98a402fe7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3578&q=10',
      line1: 'Be ready for the',
      line2: 'greatest underwater aquarium',
      line3: 'on the planet.',
    },
    {
      imageURL:
        'https://www.nusaltlaser.com/wp-content/uploads/2015/07/coachella13c_108.jpg',
      line1: 'Embrace the greatest',
      line2: 'and the biggest lights show',
      line3: 'that you ever witnessed',
    },
  ];

  const [timerIndex, setTimerIndex] = useState(0);
  const [currentTheme, setCurrentTheme] = useState(Themes[1]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimerIndex(timerIndex + 1);
    }, 3000);
    return () => clearTimeout(timer);
  });

  // this changes when timerIndex changes and updates the currentTheme based on timerIndex
  useEffect(() => {
    setCurrentTheme(Themes[timerIndex % Themes.length]);
  }, [timerIndex]);

  return (
    <>
      <div className="">
        <Navbar />
        <HeroImage theme={currentTheme} />
        <ResortSection />
        <Attractions />
        <AboutTransvision />
      </div>
    </>
  );
}

const ScrollDown = () => {
  return (
    <div class="center text-center">
      <svg width="20" height="45" viewBox="0 0 50 130">
        <rect
          id="scroll"
          x="0"
          y="5"
          rx="25"
          ry="25"
          width="50"
          height="120"
          stroke="#fff"
          fill="#00000000"
          stroke-width="4"
        ></rect>
        <circle id="circleshape" cx="25" cy="32" r="8" fill="#fff"></circle>
      </svg>
    </div>
  );
};

const Navbar = () => {
  return (
    <>
      <div className="h-32 flex items-center pl-2 pr-4 lg:pl-20 lg:pr-20 absolute top-0 z-10 w-full">
        <div className="flex justify-start w-1/2">
          <h1
            data-aos="fade-up"
            data-aos-delay="100"
            className="text-white lg:text-3xl flex items-center"
          >
            <img width="150px" src={Logo} alt="Transvision Logo" />
            <img
              className="w-1/5"
              src={TransvisionTextLogo}
              alt="Transvision Logo"
            />
          </h1>
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          className="flex justify-end w-1/2"
        >
          <h1 className="text-white">
            <img
              className="cursor-pointer"
              alt="Menu"
              src="https://img.icons8.com/ios-filled/25/ffffff/menu.png"
            />
          </h1>
        </div>
      </div>
    </>
  );
};

const HeroImage = ({ theme }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${theme.imageURL})`,
        backgroundSize: 'cover',
      }}
      className="h-screen w-screen flex items-center justify-center relative"
    >
      <h1 className="text-white text-3xl p-10 text-center lg:text-left lg:text-6xl font-semibold">
        <p data-aos="fade-up" data-aos-delay="100" className="lg:text-left">
          {theme.line1}
        </p>
        <p data-aos="fade-up" data-aos-delay="200" className="lg:text-left">
          {theme.line2}
        </p>
        <p data-aos="fade-up" data-aos-delay="300" className="lg:text-left">
          {' '}
          {theme.line3}
        </p>
      </h1>
      <div className="absolute bottom-0 left-0 right-0 pb-20 flex flex-col items-center justify-center">
        <ScrollDown />
        <p className="text-xs text-white uppercase p-2"> Scroll </p>
      </div>
    </div>
  );
};

const ResortSection = () => {
  return (
    <div className="min-h-screen w-screen text-center p-10 lg:p-20">
      <p className=" text-gray-900 font-bold text-5xl font-bebas">RESORT</p>
      <div className="mt-20 lg:flex">
        <div className="mb-10 lg:p-2 lg:w-2/3 shadow">
          <img
            alt="Resort"
            src="https://images.unsplash.com/photo-1542314831-068cd1dbfeeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=20"
          />
        </div>
        <div className="lg:w-1/3 text-left lg:p-20">
          <p>
            Built on the top of a hill, beside Essel World and Water Kingdom,
            Transvision is a 5* Resort with breathtaking, haunting views that
            embrace the plain of Gorai and the surrounding areas. Our aim is to
            provide personalized service and authentic experiences in a
            sophisticated and luxurious environment, where seasoned travelers
            will find a place like home
          </p>
          <br />
          <p>
            At a privileged location and within close distance from famous
            tourist destinations, such as Essel World, Water Kingdom, Aksha
            beach, Our hotel ideally combines the comforts of a modern city
            hotel with the relaxing atmosphere and the great facilities of a
            resort.
          </p>
          <br />
          <p>
            The simple architecture that is harmonically merged with the
            environment, the modern interior design, the artfully designed
            bedrooms, the infinity pool that is overlooking the city, the spa
            with its interior pool, the refined restaurant, the rooftop bar, as
            well as the fully equipped conference center, all these compose a
            charming result that will amaze you from the first moment.
          </p>
        </div>
      </div>
      <div className="lg:flex pt-10">
        <div className="p-2 lg:w-1/5 shadow">
          <img
            alt="Resort"
            src="https://images.unsplash.com/photo-1540541338287-41700207dee6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=20"
          />
        </div>
        <div className="p-2 lg:w-1/5 shadow">
          <img
            alt="Resort"
            src="https://images.unsplash.com/photo-1583416750470-965b2707b355?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=20"
          />
        </div>
        <div className="p-2 lg:w-1/5 shadow">
          <img
            alt="Resort"
            src="https://images.unsplash.com/photo-1541480551145-2370a440d585?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2549&q=20"
          />
        </div>
        <div className="p-2 lg:w-1/5 shadow">
          <img
            alt="Resort"
            src="https://images.unsplash.com/photo-1583231684537-bd79dc887244?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=20"
          />
        </div>
        <div className="p-2 lg:w-1/5 shadow">
          <img
            alt="Resort"
            src="https://images.unsplash.com/photo-1561501900-3701fa6a0864?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=20"
          />
        </div>
      </div>
    </div>
  );
};

const Attractions = () => {
  return (
    <div className="bg-black min-h-screen w-screen text-center p-5 lg:p-5">
      <p className=" text-white font-semibold text-5xl font-bebas mb-10">
        Attractions
      </p>
      <div className="lg:flex lg:pl-20 lg:pr-20">
        <div className="p-2 lg:w-1/3 rounded">
          <img
            style={{ minHeight: '300px' }}
            alt="Resort"
            src="https://images.unsplash.com/photo-1567617849031-8655483b300b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=20"
          />
          <p className="text-white font-bold text-2xl pt-3">
            Largest Amusement Park
          </p>
        </div>
        <div className="p-2 lg:w-1/3 rounded">
          <img
            style={{ minHeight: '300px' }}
            alt="Resort"
            src="https://images.unsplash.com/photo-1519766030446-ae88fde27309?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3578&q=20"
          />
          <p className="text-white font-bold text-2xl pt-3">
            Underwater Museum
          </p>
        </div>
        <div className="p-2 lg:w-1/3 rounded">
          <img
            style={{ minHeight: '300px' }}
            alt="Resort"
            src="https://cdn.cnn.com/cnnnext/dam/assets/171117133615-maldives-hurawalhi-undersea-restaurant-interior-1-full-169.jpg"
          />
          <p className="text-white font-bold text-2xl pt-3">
            Underwater Restaurants
          </p>
        </div>
      </div>
      {/** New Row */}

      <div className="lg:flex lg:pl-20 lg:pr-20">
        <div className="p-2 lg:w-1/3 rounded">
          <img
            style={{ minHeight: '300px' }}
            alt="Resort"
            src="https://qph.fs.quoracdn.net/main-qimg-696162f0f014ae84fa86d841fa92d82d.webp"
          />
          <p className="text-white font-bold text-2xl pt-3">
            5th Dimension Multiplex
          </p>
        </div>
        <div className="p-2 lg:w-1/3 rounded">
          <img
            style={{ minHeight: '300px' }}
            alt="Resort"
            src="https://www.nusaltlaser.com/wp-content/uploads/2015/07/coachella13c_108.jpg"
          />
          <p className="text-white font-bold text-2xl pt-3">Laser Shows</p>
        </div>
        <div className="p-2 lg:w-1/3 rounded">
          <img
            style={{ minHeight: '300px' }}
            alt="Resort"
            src="https://blog-content.ixigo.com/wp-content/uploads/2016/08/nautanki-770x430.jpg"
          />
          <p className="text-white font-bold text-2xl pt-3">Bollywood Park</p>
        </div>
      </div>
      {/** New Row */}
      <div className="lg:flex lg:pl-20 lg:pr-20">
        <div className="p-2 lg:w-1/3 rounded">
          <img
            style={{ minHeight: '300px' }}
            alt="Resort"
            src="https://images.unsplash.com/photo-1588610308964-1d75fd1a9531?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2552&q=20"
          />
          <p className="text-white font-bold text-2xl pt-3">Water Resort</p>
        </div>
        <div className="p-2 lg:w-1/3 rounded">
          <img
            style={{ minHeight: '300px' }}
            alt="Resort"
            src="https://sumfinity.com/wp-content/uploads/2017/12/Singapore-Gardens-By-The-Bay-evening.jpg"
          />
          <p className="text-white font-bold text-2xl pt-3">Theme Garden</p>
        </div>
        <div className="p-2 lg:w-1/3 rounded">
          <img
            style={{ minHeight: '300px' }}
            alt="Resort"
            src="https://assets.simpleviewinc.com/simpleview/image/upload/c_fill,h_537,q_50,w_1084/v1/clients/omaha/harrahs_int_page_b6eeb3de-fdcd-413d-8063-89fbc7158e99.jpg"
          />
          <p className="text-white font-bold text-2xl pt-3">Casinos</p>
        </div>
      </div>
    </div>
  );
};

const AboutTransvision = () => {
  return (
    <div className="min-h-screen w-screen text-center p-10 lg:p-20">
      <p className=" text-gray-900 font-bold text-5xl font-bebas">
        About Transvision
      </p>

      <div className="mt-20 lg:flex">
        <div className="lg:w-1/3 text-left lg:p-20">
          <p>
            With a strong leadership panel, Transvision has made all efforts to
            translate how entertainment is perceived having a strong backbone by
            creatively producing a huge bank of content for various broadcasters
            in India, took strides of understanding in every business venture
            with appropriate implementation power.
          </p>
          <br />
          <p>
            A vast experience in hospitality industry with expert management by
            handling a 4 star Hotel in Dubai & 5 star offices in Mumbai,
            Transvision has curated skills to empower other brands and
            businesses too with highest efficiency adhering to updated
            technologies by advancement of internal systems from time to time.
          </p>
        </div>
        <div className="lg:w-1/2 rounded">
          <img
            style={{ minHeight: '300px' }}
            alt="Resort"
            src="https://images.unsplash.com/photo-1592229505801-77b31918d822?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2467&q=20"
          />
          <p className="text-white font-bold text-2xl pt-3">Casinos</p>
        </div>
      </div>
    </div>
  );
};
