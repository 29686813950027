import React, { Fragment, useRef } from 'react';
import Logo from '../Assets/symbol.png';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

export default function Homepage() {
  const AboutRef = useRef(null);
  const WorkRef = useRef(null);
  const FutureRef = useRef(null);
  const HowRef = useRef(null);

  return (
    <Fragment>
      <video playsinline muted autoplay loop autoPlay={true}>
        <source
          src={
            'https://res.cloudinary.com/dvox8u7xp/video/upload/v1597071527/BackVideo_tca7yi.mp4'
          }
        />
      </video>
      <div className="overlay"></div>

      <div className="home-container">
        <div className="header">
          <div className="inner-header container-fluid">
            <div className="row">
              <div
                data-aos="fade-up"
                data-aos-delay="50"
                className="col-sm-3 logo"
              >
                <img width="150px" src={Logo} alt="Transvision Logo" />
              </div>
              <div className="col-sm-6 Menu">
                <div className="inner-menu">
                  <li
                    onClick={() => scrollToRef(AboutRef)}
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    Who We Are
                  </li>
                  <li
                    data-aos="fade-up"
                    onClick={() => scrollToRef(WorkRef)}
                    data-aos-delay="150"
                  >
                    What We Do
                  </li>
                  <li
                    data-aos="fade-up"
                    onClick={() => scrollToRef(FutureRef)}
                    data-aos-delay="200"
                  >
                    Who Next
                  </li>
                  <li
                    onClick={() => scrollToRef(HowRef)}
                    data-aos="fade-up"
                    data-aos-delay="250"
                  >
                    How Will We Get There
                  </li>
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </div>
        <div className="inner-container">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-1"></div>
                <div className="col-sm-4">
                  <h1 data-aos="fade-up" data-aos-delay="500">
                    The future of entertainment
                  </h1>
                  <p data-aos="fade-up" data-aos-delay="600">
                    Our vision for the near future is to make India noticeable
                    on the world map as the next Gaming destination. We aim to
                    initiate Casinos in the ‘city of dreams’- Amchi Mumbai, with
                    an international look and feel for avid gaming enthusiasts
                    and novices alike.
                  </p>
                </div>
                <div className="col-sm-7 hide-in-mobile"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={AboutRef} className="section">
        <div className="inner-section container-fluid">
          <div className="content row">
            <div className="col-sm-2 hide-in-mobile" />
            <div className="col-sm-3">
              <h4 data-aos="fade-up" data-aos-delay="100">
                who are we
              </h4>
              <p data-aos="fade-up" data-aos-delay="200">
                Transvision Production Co. Pvt. Ltd is a pioneer in hospitality,
                gaming, entertainment, and lifestyle businesses with an in-depth
                and expansive experience of spearheading high-end projects in
                these sectors.
                <br /> <br />
                We are a group of experienced members with niche skills in
                project planning, execution, liasoning and risk analysis
                pertaining to ventures covering the gamut of entertainment
                sector.
              </p>
            </div>
            <div className="col-sm-1 hide-in-mobile"></div>
            <div className="col-sm-3 headline">
              <h1 data-aos="fade-up" data-aos-delay="300">
                Glimpse into the future with transvision
              </h1>
            </div>
            <div className="col-sm-3" />
          </div>
        </div>
      </div>
      <div ref={WorkRef} className="section yellow">
        <div className="inner-section container-fluid">
          <div className="content row">
            <div className="col-sm-2 hide-in-mobile" />
            <div className="col-sm-3 headline">
              <h1 data-aos="fade-up" data-aos-delay="300">
                Building larger than life experiences.
              </h1>
            </div>

            <div className="col-sm-1 hide-in-mobile"></div>
            <div className="col-sm-3">
              <h4 data-aos="fade-up" data-aos-delay="100">
                what we do
              </h4>
              <p data-aos="fade-up" data-aos-delay="200">
                Transvision brings to the table highly acclaimed international
                players as investors while simultaneously gauging the pulse of
                the local community and maximizing profits. Our consulting team
                is equipped with end to end experience in various green and
                brown-field projects. Our passion for high- end entertainment,
                lifestyle and allied sectors ensures a world-class experience
                that becomes the highlight of a city.
                <br /> <br />
              </p>
            </div>
            <div className="col-sm-3" />
          </div>
        </div>
      </div>
      <div ref={FutureRef} className="section gaming-image">
        <div className="inner-section container-fluid">
          <div className="content row">
            <div className="col-sm-2 hide-in-mobile" />
            <div className="col-sm-3 headline">
              <h1 data-aos="fade-up" data-aos-delay="300">
                Bringing it to the home ground.
              </h1>
            </div>

            <div className="col-sm-1 hide-in-mobile"></div>
            <div className="col-sm-3">
              <h4 data-aos="fade-up" data-aos-delay="100">
                What next?
              </h4>
              <p data-aos="fade-up" data-aos-delay="200">
                Our vision for the near future is to make India noticeable on
                the world map as the next Gaming destination. We aim to initiate
                Casinos in the ‘city of dreams’- Amchi Mumbai, with an
                international look and feel for avid gaming enthusiasts and
                novices alike.
                <br /> <br />
              </p>
            </div>
            <div className="col-sm-3" />
          </div>
        </div>
      </div>
      <div ref={HowRef} className="section">
        <div className="inner-section container-fluid">
          <div className="content row">
            <div className="col-sm-3 hide-in-mobile" />
            <div className="col-sm-3 headline">
              <h1 data-aos="fade-up" data-aos-delay="100">
                Small steps, but big leaps.
              </h1>
              <br />
              <p data-aos="fade-up" data-aos-delay="300">
                Our vision for the near future is to make India noticeable on
                the world map as the next Gaming destination. We aim to initiate
                Casinos in the ‘city of dreams’- Amchi Mumbai, with an
                international look and feel for avid gaming enthusiasts and
                novices alike.
                <br /> <br />
              </p>
            </div>
            <div className="col-sm-3" />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
